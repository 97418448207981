html, body {
    height: 100%;
    background: url("./bg1.png") no-repeat center center fixed ,linear-gradient(to bottom, #254E79, #143768);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#root {
    height: 100%;
    padding: 20px;
    margin: 0;
}

.App {
    padding: 0;
    margin: 0;
    height: 100%;
    text-align: center;
    font-family: sweet-gothic, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.5px;

}

.Main-Container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}

.Main-Center {
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.Main-Right {
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.Main-Left {
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    height: 100%;
}


.Product-Image {
    max-width: 100%;
}

.Product-Holder {
    height: 100%;
    padding: 0;
    margin: 0;
}

@media (min-width: 0px) and (max-width: 770px) {
    .Product-Image {
        max-width: 100%;
    }
}

@media (min-width: 770px) and (max-width: 1200px) {
    .Product-Image {
        max-width: 65%;
    }
}


.vertical-center {
    align-items: center;
}

.App-Disclaimer {
    font-family: sweet-gothic, sans-serif;
    font-weight: 500;
    font-style: normal;
    margin: 0px;
    padding: 0px;
    line-height: 120%;
    color: white;
    font-size: calc(8px + .3vmin);
}

.App-Top-Line-1 {
    font-family: sweet-gothic, serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(12px + 1.5vmin);
    color: white;
}

.App-Top-Line-2 {
    font-family: sweet-gothic, serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(25px + 1.5vmin);
    letter-spacing: 1.5px;
    color: white;
}

.App-Top-Line-3 {
    font-family: sweet-gothic, serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(25px + 1.5vmin);
    letter-spacing: 1.5px;
    color: white;
}

.App-Top-Line-4 {
    font-family: sweet-gothic, sans-serif;
    font-weight: 300;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 150%;
    font-size: calc(13px + 1.5vmin);
    letter-spacing: 2px;
    color: white;
}

.App-Body-Large-Yellow {
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(28px + 1vmin);
    letter-spacing: 1.5px;
    color: #ff9933;
}

.App-Body-Large-Red {
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(28px + .1vmin);
    letter-spacing: 1.5px;
    color: #ed1f57;
}

.App-Body-Large-Green {
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(28px + 1vmin);
    letter-spacing: 1.5px;
    color: #b7c32f;
}

.App-MFTN-Large-Yellow {
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(12px + 1vmin);
    letter-spacing: 1.5px;
    color: #ff9933;
}


.App-MFTN-Large-Red {
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(12px + 1vmin);
    letter-spacing: 1.5px;
    color: #ed1f57;
}

.App-MFTN-Large-Green {
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(12px + 1vmin);
    letter-spacing: 1.5px;
    color: #b7c32f;
}

.App-Body-Large-Bold-White {
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(20px + 1vmin);
    color: white;
}

.App-Body-Med-Bold-White {
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(12px + 1vmin);
    color: white;
}

.App-Body-Med-Regular-White {
    font-family: sweet-gothic, serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(9px + 1vmin);
    color: white;
}

.App-Body-Med-Med-White {
    font-family: sweet-gothic, serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(13px + 1vmin);
    color: white;
}


.invalid-feedback-block {
    display: block;
}

.Sucess-Button {
    min-width: 200px;
    margin: 10px;
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: calc(8px + .8vmin);
    letter-spacing: 1px;
    color: black;
    background-color: #ffffff;
}

.btn-link {
    margin: 10px;
    font-family: sweet-gothic, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: calc(4px + .5vmin);
    color: #ffffff;
    width: 500px;
}

.linkbtn {
    margin: 10px;
    font-family: sweet-gothic, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: calc(4px + .5vmin);
    color: #ffffff;
    width: 500px;
}

form {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: sweet-gothic, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-size: calc(8px + .1vmin);
    color: white;
}

form .form-check {
    font-family: sweet-gothic, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-size: calc(8px + .1vmin);
    color: white;
}

form .btn-primary {
    font-family: sweet-gothic, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    line-height: 200%;
    font-size: calc(8px + .5vmin);
    color: white;
    background-color: #ff9933;
}

form .select2 {
    width: 100px;
}

form .select {
    width: 100px;
}
